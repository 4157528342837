<template>
  <div>
    <div class="my-n3 my-md-n5 mx-n3 subheader">

      <div class="subheader-item" :class="{'active': $route.path.includes('/lqf-tokens')}"
           v-on:click="$router.push('/governance/lqf-tokens')">
        LQF tokens
      </div>
      <div class="subheader-item" :class="{'active': $route.path.includes('/voting')}"
           v-on:click="$router.push('/governance/voting')">
        On-chain voting
      </div>
<!--      <div class="subheader-item">-->
<!--        Off-chain voting-->
<!--        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path-->
<!--              d="M5.60073 0.800293C5.4416 0.800293 5.28899 0.863507 5.17647 0.976029C5.06395 1.08855 5.00073 1.24116 5.00073 1.40029C5.00073 1.55942 5.06395 1.71204 5.17647 1.82456C5.28899 1.93708 5.4416 2.00029 5.60073 2.00029H7.15233L3.37653 5.77609C3.31923 5.83144 3.27352 5.89765 3.24207 5.97085C3.21063 6.04405 3.19407 6.12278 3.19338 6.20245C3.19269 6.28212 3.20787 6.36113 3.23804 6.43486C3.26821 6.5086 3.31276 6.57559 3.3691 6.63193C3.42543 6.68826 3.49242 6.73282 3.56616 6.76299C3.6399 6.79315 3.71891 6.80834 3.79857 6.80764C3.87824 6.80695 3.95697 6.7904 4.03018 6.75895C4.10338 6.72751 4.16958 6.6818 4.22493 6.62449L8.00073 2.84869V4.40029C8.00073 4.55942 8.06395 4.71203 8.17647 4.82456C8.28899 4.93708 8.4416 5.00029 8.60073 5.00029C8.75986 5.00029 8.91248 4.93708 9.025 4.82456C9.13752 4.71203 9.20073 4.55942 9.20073 4.40029V1.40029C9.20073 1.24116 9.13752 1.08855 9.025 0.976029C8.91248 0.863507 8.75986 0.800293 8.60073 0.800293H5.60073Z"-->
<!--              fill="#1B283F"/>-->
<!--          <path-->
<!--              d="M2.00078 2C1.68252 2 1.3773 2.12643 1.15225 2.35147C0.927209 2.57652 0.800781 2.88174 0.800781 3.2V8C0.800781 8.31826 0.927209 8.62349 1.15225 8.84853C1.3773 9.07357 1.68252 9.2 2.00078 9.2H6.80078C7.11904 9.2 7.42427 9.07357 7.64931 8.84853C7.87435 8.62349 8.00078 8.31826 8.00078 8V6.2C8.00078 6.04087 7.93757 5.88826 7.82505 5.77574C7.71252 5.66321 7.55991 5.6 7.40078 5.6C7.24165 5.6 7.08904 5.66321 6.97652 5.77574C6.864 5.88826 6.80078 6.04087 6.80078 6.2V8H2.00078V3.2H3.80078C3.95991 3.2 4.11252 3.13679 4.22505 3.02426C4.33757 2.91174 4.40078 2.75913 4.40078 2.6C4.40078 2.44087 4.33757 2.28826 4.22505 2.17574C4.11252 2.06321 3.95991 2 3.80078 2H2.00078Z"-->
<!--              fill="#1B283F"/>-->
<!--        </svg>-->
<!--      </div>-->

    </div>
    <b-row align-h="center" class="mt-3 mt-md-5">
      <b-col class="mt-3 mt-md-5">
        <b-overlay>
          <router-view/>
        </b-overlay>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  name: "Governance"
}
</script>

<style scoped lang="scss">
  .subheader {
    min-height: 48px;
    background-color: $gray-300;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 14px;
    font-weight: bold;
  }

  .subheader-item {
    padding: 8px 14px;
    border-radius: 12px;
    cursor: pointer;

    &.active {
      background-color: $color-primary;
      color: $white;
      font-weight: 700;
    }
  }

</style>
